import $ from 'jquery'
import L from 'leaflet'
import ElevationEngine from './data/ElevationEngine'
import payload from './data/payload'
import SearchEngine from './data/SearchEngine'
import State from './data/State'
import BackgroundLayer from './map/BackgroundLayer'
import Color from './map/Color'
import POIlayer from './map/POIlayer'
import Region from './map/Region'
import SnowLayer from './map/SnowLayer'
import TimeLayer from './map/TimeLayer'
import UI from './UI'
import Attribute from './ui/Attribute'
import Device from './ui/Device'
import UrlHandler from './ui/UrlHandler'
import Track from './map/Track'
import Map from './Map'
import './leaflet-convertcoords.min.js'

import '../assets/css/lexend-deca.css'
import '../assets/css/leaflet.css'
import '../assets/css/aim.css'
import '../assets/css/carousel.css'
import '../assets/css/general.css'
import '../assets/css/map-attributions.css'
import '../assets/css/map-info.css'
import '../assets/css/reports-list.css'
import '../assets/css/tabs.css'
import '../assets/css/articles.css'
import '../assets/css/cursors.css'
import '../assets/css/info-popup.css'
import '../assets/css/map-buttons.css'
import '../assets/css/mobile-detection.css'
import '../assets/css/search-bar.css'
import '../assets/css/timeline.css'
import '../assets/css/bookmarks.css'
import '../assets/css/draw.css'
import '../assets/css/legend.css'
import '../assets/css/map-contents.css'
import '../assets/css/poi-filters.css'
import '../assets/css/settings.css'
import '../assets/css/tooltip.css'
import '../assets/css/breadcrumbs.css'
import '../assets/css/filters.css'
import '../assets/css/loading-screen.css'
import '../assets/css/map.css'
import '../assets/css/print.css'
import '../assets/css/startup-messages.css'
import '../assets/css/update-window.css'
import DOMelement from './ui/DOMelement'
import matomo from './utils/matomo'
import sentry from './utils/sentry'
import getCookie from './utils/getCookie'

export const HOST = window.location.host.includes('localhost')
  ? 'http://localhost'
  : 'https://' + window.location.host

if (getCookie('privacy') === 'accepted') {
  sentry()
  matomo()
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    console.log('Try registering SW...')
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('SW registered: ', registration)
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError)
    })
  })
}

export const APP = {}

async function init () {
  new DOMelement('loading.html').addToDOM('body')

  let initPromiseResolve
  APP.promise = new Promise((resolve) => {
    initPromiseResolve = resolve
  })

  APP.data = await payload()
  APP.device = new Device()
  APP.ui = new UI()
  APP.ui.build()

  APP.data.elevationEngine = new ElevationEngine()
  APP.data.searchEngine = new SearchEngine()

  // Colors
  APP.colors = {}
  for (const [name, data] of Object.entries(APP.data.colors)) {
    APP.colors[name] = new Color(data)
  }

  // Attributes
  APP.attributes = {}
  for (const [name, data] of Object.entries(APP.data.attributes)) {
    APP.attributes[name] = new Attribute(data)
  }

  // Regions
  APP.regions = {}
  for (const [name, data] of Object.entries(APP.data.regions)) {
    APP.regions[name] = new Region({
      ...data,
      report: APP.data.reports[name],
      colors: APP.colors
    })
  }

  // POI groups
  APP.POIlayers = {}
  for (const [name, data] of Object.entries(APP.data.settings.POIlayers)) {
    APP.POIlayers[name] = new POIlayer({
      name,
      title: data.title,
      icon: data.icon,
      attributes: APP.attributes,
      filters: APP.filters,
      tiles: data.tiles,
      list: APP.data[name],
      family: name
    })
  }

  // Background layers
  APP.backgroundLayers = {}
  for (const [name, data] of Object.entries(
    APP.data.settings.backgroundLayers
  )) {
    APP.backgroundLayers[name] = new BackgroundLayer(data)
  }

  // Snow layers
  APP.snowLayers = {}
  for (const [name, data] of Object.entries(APP.data.settings.snowLayers)) {
    if (data.timeLayer) {
      APP.snowLayers[name] = new TimeLayer({
        ...data,
        indexes: APP.data.fresh_snow - 1
      })
    } else {
      APP.snowLayers[name] = new SnowLayer(data)
    }
  }

  // Startup settings
  APP.state = new State({ ...APP.data.settings.startupDefaults[APP.device.type] })

  APP.urlHandler = new UrlHandler()
  if (!APP.state.updateFromURL()) {
    APP.state.updateFromLocalStorage()
  }

  // File reader
  APP.data.fileReader = new FileReader()
  APP.data.fileReader.addEventListener('load', (e) => {
    const split = e.target.result.split(',')
    const content = atob(split.at(-1))
    const contentType = split[0].split(':').at(-1)

    let result
    if (contentType.includes('gpx')) {
      result = L.ConvertCoords.GPX.parse(content)
    } else if (contentType.includes('kml')) {
      result = L.ConvertCoords.KML.parse(content)
    } else {
      APP.ui.infoPopup.peek('no_track_found')
    }

    if (result) {
      const tracks = []
      result.eachLayer((layer) => {
        if (layer instanceof L.Path) {
          const track = new Track({
            geojson: layer.toGeoJSON(),
            visible: true
          })
          track.calculateElevation()
          tracks.push(track)
        }
      })

      tracks.forEach((track) => {
        APP.tracks.push(track)
      })

      if (tracks.length === 0) {
        APP.ui.infoPopup.peek('no_track_found')
      } else {
        APP.ui.infoPopup.peek('tracks_added')
      }
    }
  })

  // Map
  await Promise.all(APP.ui.promises)
  APP.map = new Map()

  // Tracks
  APP.tracks = []
  Track.fetchLocalStorage()

  // Update UI, URL and map
  APP.map.update()
  APP.ui.update()
  APP.urlHandler.update()

  initPromiseResolve()

  $(() => {
    // Hide loading page
    $('div.loading-screen').addClass('hidden')
    const duration =
            (parseFloat($('div.loading-screen').css('transition-delay')) +
                parseFloat(
                  $('div.loading-screen').css('transition-duration')
                )) *
            1000
    setTimeout(() => {
      $('div.loading-screen').remove()
    }, duration)
  })
}

init()
